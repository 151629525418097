/** * 解决方案  */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/shortMessage-solution-banner01.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">短信营销全域解决方案</p>
            <p class="banner-text">7*24小时“多对一”顾问式服务，免费技术支持</p>
          </div>
      </v-img>
    </v-banner>

    <!-- 我们的能力 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>OUR ABILITY</p>
        <div>
          <span>我们的能力</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="4" v-for="n in warp1" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- 解决的问题 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>PROBLEMS SOLVED</p>
        <div>
          <span>解决的问题</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="3" v-for="n in warp2" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>


    <!-- 解决的问题 -->
    <v-card class="warps warp3" :flat="true">
      <div class="public-title">
        <p>FAST ACCESS PROCESS</p>
        <div>
          <span>快速接入流程</span>
        </div>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/other/shortMessage-shot-warp3-img.png" />
      </div>
    </v-card>
    
    
  </v-app>
</template>

<script>


export default {
  name: 'Solution',
  components: {
  },
  data() {
    return {
      tab: null,
      warp1: [
        { 
          id:1, 
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img.png", 
          tit: '签名自定义',
          content: '支持公司名、网站名、商标名、小程序名等'
        },
        { 
          id:2,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img2.png", 
          tit: '正文模板',
          content: '模板短信可通过参数实现个性化定制'
        },
        { 
          id:3,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img3.png", 
          tit: '短信发送',
          content: '可通过控制台、云短信API或SDK发送短信'
        },
        { 
          id:4,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img4.png", 
          tit: '防盗刷',
          content: '可设置单日发送数量，超出后放刷自动触发'
        },
        { 
          id:5,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img5.png", 
          tit: '统计报表',
          content: '实时展示短信发送数量及成功率，支持按单个手机号查看'
        },
        { 
          id:6,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img6.png", 
          tit: '状态通知',
          content: '控制台实时展示短信发送状态及短信拆分条数'
        },
      ],
      warp2: [
        { 
          id:1, 
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img.png", 
          tit: '效率低下',
          content: '多种场景下需要自行编辑文本内容，费时又费力'
        },
        { 
          id:2,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img2.png", 
          tit: '区域局限',
          content: '仅能触达国内用户，无法触达海外更广阔的市场中的用户'
        },
        { 
          id:3,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img3.png", 
          tit: '数据无反馈',
          content: '多维度数据统计，掌握每一条短信的详细数据'
        },
        { 
          id:4,  
          src: "https://h5.ophyer.cn/official_website/other/shortMessage-solution-warp1-img4.png", 
          tit: '安全性低',
          content: '无法预防短信轰炸及短信被盗刷等安全问题'
        },
      ],
    }
  },
  watch: {
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
}


.warps {
  margin: 0 auto;
  text-align: center;
  width: 1200px !important;
}

.warp1 {
  .col-4{
    height: 197px !important;
  }
  .img_title {
    width: 266px;
    margin: 0 auto;
    text-align: center;
    img {
      margin: 0 auto;
      width: 96px ;
      height: 96px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 15px;
      line-height: 20px;
    }
  }
}

.warp2 {
  .img_title {
    width: 266px;
    margin: 0 auto;
    text-align: center;
    img {
      margin: 0 auto;
      width: 96px ;
      height: 96px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 15px;
      line-height: 20px;
    }
  }
}


.warp3 {
  margin-bottom: 50px;
  .con {
    height: 120px;
  }
}


</style>
